<template>
    <v-container fluid>
        <DailyReportsHeader
            :items="items"
            @filter="fetchData"
        />
        <v-row>
            <v-col class="ml-3">
                <h4>Daily report</h4>
            </v-col>
        </v-row>
        <div v-if="loading">
            <loader-single></loader-single>
        </div>
        <DailyReportsList :items="items"/>
    </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DailyReportsHeader from '@/components/reports/daily/DailyReportsHeader';
import DailyReportsList from '@/components/reports/daily/DailyReportsList';
import { createDatesRangeObject } from '@/helpers/reports';
import { DAILY_REPORTS_REQUEST } from '@/store/actions/reports';

const { mapGetters, mapActions } = createNamespacedHelpers('reports');

export default {
    components: {
        DailyReportsHeader,
        DailyReportsList,
    },
    data() {
        return {
            loading: true
        };
    },
    computed: {
        ...mapGetters({
        items: 'dailyReports'
        }),
    },
    methods: {
        loaderTimer() {
            setTimeout(() => { this.loading = false }, 3000)
        },
        ...mapActions({
            getDailyReports: DAILY_REPORTS_REQUEST,
        }),
        async fetchData(params) {
            const isDatesRangeChanged = params?.dates.isChanged;

            if (isDatesRangeChanged) {
                await this.fetchReports(params);
            } else {
                this.fetchReports(params);
            }
        },

        async fetchReports(params) {
            const datesRangeParam = createDatesRangeObject(params?.dates.range);

            await this.getDailyReports({
                ...datesRangeParam,
                usernames: params?.usernames.length > 0 ? params.usernames : this.users,
                groups: params?.groups.length > 0 ? params.groups : this.groups,
            });
            this.loading = false;
        },

    },
    async created() {
        this.loaderTimer();
        await this.fetchReports();
    },
};
</script>
