<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="calcHits()"
                    item-key="_id"
                    dense
                    fixed-header
                >
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    props: {
        items: Array,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Name',
                    value: 'name[0]',
                },  
                {
                    text: 'Tenant Id',
                    value: '_id',
                },
                {
                    text: 'First Query',
                    value: 'firstQuery',
                    align: 'center',
                },
                {
                    text: 'Last Query',
                    value: 'lastQuery',
                    align: 'center',
                },
                {
                    text: '#Hits',
                    value: 'hits',
                    align: 'end',
                },
                {
                    text: '#Auto Closed',
                    value: 'autoClosed',
                    align: 'end',
                },
                {
                    text: '#Total',
                    value: 'totalQueries',
                    align: 'end',
                },
            ],
        };
    },
    methods: {
        calcHits() {
            return this.items.map((item) => {
                item.autoClosed = parseInt(item.autoClosed) ? item.autoClosed : 0;
                return {...item, hits: parseInt(item.totalQueries) - item.autoClosed}
            });
        }
    },
};
</script>
