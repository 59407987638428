<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    dense
                    fixed-header
                    :search="selectedMonthsAsString"
                    :custom-filter="filterByMonths"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    props: {
        items: Array,
        selectedMonthsArray: Array,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Tenant',
                    value: 'tenant[0].name',
                },
                {
                    text: 'First query',
                    value: 'firstQuery',
                    align: 'center',
                },
                {
                    text: 'Last query',
                    value: 'lastQuery',
                    align: 'center',
                },
                {
                    text: '#Reports',
                    value: 'count',
                    align: 'end',
                },
            ],
        };
    },
    computed: {
        selectedMonthsAsString() {
            return this.selectedMonthsArray.join(',');
        },
    },
    methods: {
        filterByMonths(_, search, item) {
            const months = search.split(',');
            const { month } = item._id;

            return months.includes(String(month));
        },
    }
};
</script>
