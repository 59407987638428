<template>
    <v-container fluid>
        <v-row no-gutters justify="space-between" align="center" class="elevation-1">
            <v-col cols="12" sm="9">
                <ReportsFilter
                    @filter="$emit('filter', $event)"
                />
            </v-col>
            <v-col sm="2" class="text-right pr-2">
                <v-btn class="ma-3">
                    <JsonCSV :data="jsonItems" :name="downloadName()">
                        Export to CSV
                    </JsonCSV>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import JsonCSV from 'vue-json-csv';
import ReportsFilter from '../ReportsFilter';

export default {
    components: {
        ReportsFilter,
        JsonCSV,
    },
    props: {
        items: Array,
    },
    methods: {
        downloadName() {
            const t_date = this.moment().format("YYYY-MM-DD_HH-mm");
            return `report_${t_date}.csv`;
        }
    },
    computed: {
        jsonItems() {
            return this.items.map((item) => ({
                'Tenant': item._id,
                'Name': item.name[0],
                'First query': item.firstQuery,
                'Last query': item.lastQuery,
                'Hits': item.totalQueries - item.autoClosed,
                'Auto closed': item.autoClosed,
                'Total': item.totalQueries
            }));
        },
    },
};
</script>