import moment from "moment";

export function createDatesRangeObject(datesRange = []) {
  return datesRange.length === 0
    ? {
        startDate: moment().subtract(process.env.VUE_APP_BACK_DAYS_FILTER, "days").toISOString(),
        endDate: moment().toISOString(),
      }
    : {
        startDate: datesRange[0],
        endDate: datesRange[1],
      };
}

export default {
  createDatesRangeObject,
};
