<template>
    <v-container fluid>
        <ActivityReportsHeader
            :items="items"
            v-model="selectedMonthsArray"
            @filter="loadData"
        />

        <v-row>
            <v-col class="ml-3">
                <h4>Activity report</h4>
            </v-col>
        </v-row>
        <div v-if="loading">
            <loader-single></loader-single>
        </div>

        <ActivityReportsList
            :items="items"
            :selected-months-array="selectedMonthsArray"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActivityReportsHeader from '@/components/reports/activity/ActivityReportsHeader';
import ActivityReportsList from '@/components/reports/activity/ActivityReportsList';
import { createDatesRangeObject } from '@/helpers/reports';
import { ACTIVITY_REPORTS_REQUEST } from '@/store/actions/reports';

const { mapGetters, mapActions } = createNamespacedHelpers('reports');

export default {
    components: {
        ActivityReportsHeader,
        ActivityReportsList,
    },
    data() {
        return {
            loading: true,
            selectedMonthsArray: [],
        };
    },
    computed: {
        ...mapGetters({ items: 'activityReports', isLoading: 'isLoading' }),
    },
    methods: {
        loaderTimer() {
            setTimeout(() => { this.loading = false }, 3000)
        },
        ...mapActions({
            getActivityReports: ACTIVITY_REPORTS_REQUEST,
        }),
        async loadData(params) {
            const datesRangeParam = createDatesRangeObject(params?.dates.range);

            await this.getActivityReports(datesRangeParam);
            this.loading = false;
        },
    },
    created() {
        this.loaderTimer();
        this.loadData();
    },
};
</script>
