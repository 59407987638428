<template>
    <v-container>
        <v-row align="center">
            <v-menu
                v-model="showCalendar"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                @input="updateValue(true)"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        label="Dates range:"
                        :value="dateRangeText"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        style="min-width: 200px; max-width: 300px"
                        append-icon="close"
                        @click:append="resetValue"
                        class="pl-3 pr-3"
                    />
                </template>
                <v-date-picker v-model="params.dates.range" range />
            </v-menu>

            <v-select v-if="false"
                label="by Time Zone:"
                :items="tzList"
                :item-text="'name'"
                :item-value="'tzValue'"
                style="max-width: 250px"
                prepend-icon="history"
                v-model="params.selectedTz"
                
                class="pl-3 pr-3"
            /> <!-- @change="setTimeZone(params.selectedTz)" -->

            <v-autocomplete
                label="Group name"
                :items="groups"
                v-if="groups"
                v-model="params.groups"
                multiple
                chips
                deletable-chips
                @input="updateValue(false)"
                style="max-width: 250px"
                class="pl-3 pr-3"
            />

            <v-autocomplete
                label="User name"
                :items="filteredUsers"
                v-if="users"
                v-model="params.usernames"
                multiple
                chips
                deletable-chips
                @input="updateValue(false)"
                style="max-width: 250px"
                class="pl-3 pr-3"
            />

        </v-row>
    </v-container>
</template>

<script>
import lodash from "lodash";
// import moment from 'moment';
// import * as momentTz from 'moment-timezone';

export default {
    props: ["value", "users", "groups", "groupsUsernamesObj"],
    data() {
        return {
            params: {
                selectedTz: '',
                dates: {
                range: [],
                isChanged: true,
                },
                usernames: [],
                groups: [],
            },
            tzList: [
                {
                    name: 'CT',
                    tzValue: 'America/Chicago'
                },
                {
                    name: 'GMT',
                    tzValue: 'Europe/London'
                }
            ],
            showCalendar: false,
        };
    },
    computed: {
        dateRangeText() {
            return this.params.dates.range.map((d) => this.moment(d).format("YYYY-MM-DD")).join(" ~ ");
        },
        filteredUsers() {
            const selectedGroups = this.params.groups.length > 0 ? this.params.groups : this.groups;

            if (selectedGroups && selectedGroups.length === 0) {
                return this.users;
            }
            if (!this.groupsUsernamesObj || Object.keys(this.groupsUsernamesObj).length === 0) {
                return this.users;
            }

            const users = [];
            // Merge all the usernames associated with the selected groups into a single array
            selectedGroups.forEach((group) => {
                users.push(...this.groupsUsernamesObj[group]);
            });

            // Remove duplicates
            return lodash.uniq(users);
        },
    },
    methods: {
        updateValue(isDatesRangeChanged) {
            this.rememberDateRange();
            this.params.dates.isChanged = isDatesRangeChanged;
            this.$emit("filter", this.params);
        },
        resetValue() {
            if (!this.restoreDateRange()) {
                this.params.dates = {
                    range: [
                        //this.moment().subtract(process.env.VUE_APP_BACK_DAYS_FILTER, "days").toISOString(),
                        this.moment().startOf('day').toISOString(),
                        this.moment().toISOString(),
                    ],
                    isChanged: true,
                };
            }
            this.rememberDateRange();
            this.$emit("filter", this.params);
        },
        // setTimeZone(tzSelected) {
        //     localStorage.setItem('reportFilterTZ', tzSelected);
        //     // console.log('========', momentTz.tz(localStorage.getItem('date-range-start'), tzSelected).format(localStorage.getItem('dateFormat')));
        //     this.params.dates = {
        //             range: [
        //                 momentTz.tz(localStorage.getItem('date-range-start'), tzSelected).toISOString(),
        //                 momentTz.tz(localStorage.getItem('date-range-end'), tzSelected).toISOString()
        //             ],
        //             isChanged: true,
        //         };
        //     this.rememberDateRange();
        //     this.$emit("filter", this.params);
        // },
        rememberDateRange() {
            localStorage.setItem('date-range-start', this.params.dates.range[0]);
            localStorage.setItem('date-range-end', this.params.dates.range[1]);
        },
        restoreDateRange() {
            if (localStorage.getItem('date-range-start') && localStorage.getItem('date-range-end')) {
                this.params.dates = {
                    range: [
                        localStorage.getItem('date-range-start'),
                        localStorage.getItem('date-range-end')
                    ]
                }
                
                return true;
            }
            else {
                return false;
            }
        },
        TZFilterSet() {
            if (localStorage.getItem('reportFilterTZ')) {
                this.selectedTz = localStorage.getItem('reportFilterTZ');
            }
        }
    },
    created() {
        this.resetValue();
        this.TZFilterSet();
        // console.log('DATE RANGE ==== ', this.params.dates.range);
    }
};
</script>
