<template>
    <v-container fluid>
        <v-row no-gutters align="center" class="elevation-1">
            <v-col cols="12" sm="4">
                <ReportsFilter @filter="$emit('filter', $event)" />
            </v-col>
            <!--
            <v-col>
                <v-autocomplete 
                label="Filter months"
                :items="months"
                :value="value"
                @input="$emit('input', $event)"
                multiple
                style="max-width: 250px"
                />
            </v-col>
            -->
            <v-col class="text-right pr-2">
                <v-btn class="ma-3">
                    <JsonCSV :data="jsonItems" :name="downloadName()">
                        Export to CSV
                    </JsonCSV>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import JsonCSV from 'vue-json-csv';
import ReportsFilter from '@/components/reports/ReportsFilter';

export default {
    components: {
        ReportsFilter,
        JsonCSV,
    },
    props: {
        value: Array,
        items: Array,
    },
    methods: {
            downloadName() {
                const t_date = this.moment().format("YYYY-MM-DD_HH-mm");
                return `activityReport_${t_date}.csv`;
            }
        },
    computed: {
        months() {
            return this.items.map((item) => item._id.month).sort((a, b) => a - b);
        },    
        jsonItems() {
            return this.items.map((item) => ({
                'Tenant': item.tenant[0].tenantId,
                'Name': item.tenant[0].name,
                'Start date': item.firstQuery,
                'End date': item.lastQuery, 
                'Total': item.count,
            }));
        }
    },
};
</script>
